<template lang="pug">
.flex.flex-col.w-full(style='height: 75vh')
  p.font-bold.text-darkblue.text-xl.font-SourceSansPro {{ $t("notifications.velocity.speed") }}
  .flex.mt-3.relative.mb-4
    .flex.flex-col
      p.text-annotationColor.font-SourceSansPro.text-base.font-semibold {{ $t("notifications.velocity.min") }}
      .flex.items-center.mt-2
        skif-input-number(
          :disabled='isAllowedCreate',
          v-model.number='notification.speed_notless',
          :error='validate'
        )
        p.text-annotationColor.font-SourceSansPro.text-xs.font-semibold.ml-3 {{ $t("notifications.kmph") }}
    .flex.flex-col.ml-5
      p.text-annotationColor.font-SourceSansPro.text-base.font-semibold {{ $t("notifications.velocity.max") }}
      .flex.items-center.mt-2
        skif-input-number(
          :disabled='isAllowedCreate',
          v-model.number='notification.speed_notmore',
          :error='validate'
        )
        p.text-annotationColor.font-SourceSansPro.text-xs.font-semibold.ml-3 {{ $t("notifications.kmph") }}
    transition(name='el-zoom-in-top')
      .text-notify.text-xs.top-full.left-0.absolute(v-if='validate') {{ $t("notifications.warning") }}
  .flex.relative.flex.items-center
    skif-checkbox(v-model='notification.use_speed_limits')
    span.leading-10.font-bold.text-sm.transition-colors.duration-300.text-lightblue  {{ $t("notifications.use_speed_limit") }} {{ notification.speed_notmore ? notification.speed_notmore : 0 }} {{ $t("km_hour") }}
    .custom-tooltip(@mouseenter="showTooltip" @mouseleave="hideTooltip")
      svg(
      width='15',
      height='15',
      fill='none',
      xmlns='http://www.w3.org/2000/svg'
      )
        path(
          fill-rule='evenodd',
          clip-rule='evenodd',
          d='M7.5 13.636c3.577 0 6.136-2.559 6.136-6.136S11.077 1.364 7.5 1.364 1.364 3.923 1.364 7.5s2.559 6.136 6.136 6.136zM7.5 15a7.5 7.5 0 110-15 7.5 7.5 0 010 15zM4.964 5.63c.05-1.264.913-2.22 2.527-2.22 1.47 0 2.46.873 2.46 2.064 0 .863-.434 1.47-1.169 1.91-.718.422-.924.717-.924 1.29v.34H6.706L6.7 8.569c-.027-.785.312-1.286 1.075-1.742.673-.407.912-.73.912-1.303 0-.629-.495-1.09-1.257-1.09-.769 0-1.264.461-1.314 1.196H4.964zm2.326 5.96a.715.715 0 01-.729-.717c0-.406.323-.718.73-.718.417 0 .734.312.734.718a.713.713 0 01-.735.718z',
          fill='#2F80ED'
        )
      div.tooltip-content(v-if="isTooltipVisible") {{ $t("notification.use_speed_description") }}

      //- el-tooltip.ml-2(
      //-     effect='dark',
      //-     :content='$t("notification.use_speed_description")',
      //-     placement='bottom',
      //-     :offset='0',
      //-     :visible-arrow='false'
      //-     v-model='isTooltipVisible'
      //-     @mouseenter='isTooltipVisible = true'
      //-     @mouseleave='handleMouseLeave'
      //-   )
      //-     svg(
      //-       width='15',
      //-       height='15',
      //-       fill='none',
      //-       xmlns='http://www.w3.org/2000/svg'
      //-     )
      //-       path(
      //-         fill-rule='evenodd',
      //-         clip-rule='evenodd',
      //-         d='M7.5 13.636c3.577 0 6.136-2.559 6.136-6.136S11.077 1.364 7.5 1.364 1.364 3.923 1.364 7.5s2.559 6.136 6.136 6.136zM7.5 15a7.5 7.5 0 110-15 7.5 7.5 0 010 15zM4.964 5.63c.05-1.264.913-2.22 2.527-2.22 1.47 0 2.46.873 2.46 2.064 0 .863-.434 1.47-1.169 1.91-.718.422-.924.717-.924 1.29v.34H6.706L6.7 8.569c-.027-.785.312-1.286 1.075-1.742.673-.407.912-.73.912-1.303 0-.629-.495-1.09-1.257-1.09-.769 0-1.264.461-1.314 1.196H4.964zm2.326 5.96a.715.715 0 01-.729-.717c0-.406.323-.718.73-.718.417 0 .734.312.734.718a.713.713 0 01-.735.718z',
      //-         fill='#2F80ED'
      //-       )

  //- p.font-bold.text-darkblue.text-base.mt-4 Геозона
  //- .mt-3
  //-   skif-search.w-64(v-model='filterString', :placeholder='$t("search")')
  
  .overflow-y-auto.mt-2.border-t.border-dividerColor(
    @scroll.passive='handleScroll'
  )
    resize-observer(@notify='handleResize')
    table.w-full
      thead.text-left
        tr
          th.bg-white.top-0.sticky
            span.font-bold.text-darkblue.text-xl.font-SourceSansPro {{ $t("notifications.geozones.geozones") }}
          th.bg-white.top-0.sticky.z-10.w-10
            skif-checkbox(
              v-model='isAllChecked',
              :indeterminate='isIndeterminate',
              @change='checkAllUnits($event)',
              :disabled='!filterData.length'
            )
        tr.border-b.border-reallylightblue
          th.sticky.bg-white.z-10(style='top: 32px', colspan='2')
            skif-search.w-64.my-2(
              v-model='filterString',
              :placeholder='$t("search")',
              class='w-1/2',
              @searching='searching'
            )
      tbody
        tr(:style='{ height: this.topPaddingHeight + "px" }')
        tr.border-b.border-reallylightblue(
          v-for='geozone in visibleItems',
          style='height: 43px'
        )
          td
            span.leading-10.font-bold.text-sm.transition-colors.duration-300(
              :class='geozone.isInArray ? "text-darkblue" : "text-annotationColor"'
            ) {{ geozone.name }}
          td.w-10
            skif-checkbox(
              v-model='geozone.isInArray',
              @change='changeChecked(geozone, $event)'
            )
        tr(:style='{ height: this.bottomPaddingHeight + "px" }')
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['notification', 'isVelocityParamsMissing'],
  data() {
    return {
      isTooltipVisible: false,
      isAllChecked: false,
      isIndeterminate: false,
      filterString: '',
      cellHeight: 43,
      viewportHeight: 0,
      totalHeight: 0,
      topPaddingHeight: 0,
      bottomPaddingHeight: 0,
      bufferHeight: 0,
      toleranceHeight: 43 * 5,
      tolerance: 5,
      bufferedItems: 0,
      startIndex: 0,
      use_speed_limits: false
    }
  },
  computed: {
    visibleItems() {
      return this.filterData.slice(
        this.startIndex,
        this.startIndex + this.bufferedItems
      )
    },

    filterData() {
      return this.geozones.reduce((result, geozone) => {
        const isInArray = this.notification.geozones
          .map((k) => k.id)
          .includes(geozone.id)
        return JSON.stringify(geozone)
          .toLowerCase()
          .includes(this.filterString.toLowerCase())
          ? [...result, { ...geozone, isInArray }]
          : result
      }, [])
    },
    geozonesIds() {
      return this.filterData.map((k) => k.id)
    },
    ...mapGetters('login', ['role', 'disabledRole', 'notRole']),

    ...mapGetters('geozones', {
      geozones: 'geozonesIdNameImei'
    }),
    isAllowedCreate() {
      return this.disabledRole && this.role !== 'OPERATOR'
    },
    validate: {
      get() {
        return this.isVelocityParamsMissing
      },
      set(newvalue) {
        this.$emit('updateVelocityParams', newvalue)
      }
    }
  },

  watch: {
    'filterData.length': function (val) {
      if (val < this.startIndex) {
        this.startIndex = 0
      }
      this.topPaddingHeight = Math.max(this.startIndex * this.cellHeight, 0)
      this.totalHeight = val * this.cellHeight
      this.bottomPaddingHeight = Math.max(
        this.totalHeight - this.topPaddingHeight - this.bufferHeight,
        0
      )
    },

    'notification.speed_notless': function (val) {
      this.validate = val === '' && +this.notification.speed_notmore === ''
    },
    'notification.speed_notmore': function (val) {
      this.validate = val === '' && +this.notification.speed_notless === ''
    },

    // 'notification.geozones': function (val) {
    //   const valIds = val.map((k) => k.id)
    //   this.isAllChecked = this.geozonesIds.every((geozonesId) =>
    //     valIds.includes(geozonesId)
    //   )
    //   this.isIndeterminate =
    //     !this.isAllChecked &&
    //     this.geozonesIds.some((geozonesId) => valIds.includes(geozonesId))
    // },
    filterData() {
      const valIds = this.notification.geozones.map((k) => k.id)
      this.isAllChecked =
        !!this.geozonesIds.length &&
        this.geozonesIds.every((geozonesId) => valIds.includes(geozonesId))
      this.isIndeterminate =
        !this.isAllChecked &&
        this.geozonesIds.some((geozonesId) => valIds.includes(geozonesId))
    }
  },
  methods: {
    showTooltip() {
      console.log('tootlti')
      this.isTooltipVisible = true
    },
    hideTooltip() {
      this.isTooltipVisible = false
    },
    searching(val) {
      this.filterString = val
    },
    handleScroll(event) {
      this.startIndex = Math.round(event.target.scrollTop / this.cellHeight)
      this.topPaddingHeight = Math.max(this.startIndex * this.cellHeight, 0)
      this.bottomPaddingHeight = Math.max(
        this.totalHeight - this.topPaddingHeight - this.bufferHeight,
        0
      )
    },

    handleResize({ height }) {
      this.viewportHeight = height
      this.totalHeight = this.filterData.length * this.cellHeight
      this.bufferHeight = this.viewportHeight + 2 * this.toleranceHeight
      this.bottomPaddingHeight = Math.max(
        this.totalHeight - this.bufferHeight,
        0
      )
      this.bufferedItems = Math.round(
        this.viewportHeight / this.cellHeight + 2 * this.tolerance
      )
    },
    checkAllUnits($event) {
      // this.notification.geozones = value ? this.geozones : []
      if ($event.target.checked) {
        // eslint-disable-next-line vue/no-mutating-props
        this.notification.geozones = this.filterData
        this.isAllChecked = true
        this.isIndeterminate = false
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.notification.geozones = []
        this.isAllChecked = false
        this.isIndeterminate = false
      }
    },
    changeChecked(geozone, $event) {
      // eslint-disable-next-line vue/no-mutating-props
      this.notification.geozones = $event.target.checked
        ? [...this.notification.geozones, geozone]
        : this.notification.geozones.filter(
            (notifyGeo) => notifyGeo.id !== geozone.id
          )
      const selectedLength = this.notification.geozones.length
      if (selectedLength === this.filterData.length) {
        this.isAllChecked = true
        this.isIndeterminate = false
      } else if (!selectedLength) {
        this.isAllChecked = false
        this.isIndeterminate = false
      } else {
        this.isAllChecked = false
        this.isIndeterminate = true
      }
    }
  },
  created() {
    const valIds = this.notification.geozones.map((k) => k.id)
    this.geozones.sort((a, b) => (valIds.indexOf(b.id) !== -1 ? 1 : -1))
    this.isAllChecked =
      !!this.geozonesIds.length &&
      this.geozonesIds.every((unitId) => valIds.includes(unitId))
    this.isIndeterminate =
      !this.isAllChecked &&
      this.geozonesIds.some((unitId) => valIds.includes(unitId))
  }
}
</script>
<style scoped lang="scss">
.custom-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;

  background: none;
  box-shadow: none;
  border: none;

  &:hover .tooltip-content {
    display: block;
  }

  .tooltip-content {
    position: fixed;
    top: 350px;
    left: 20px;
    background-color: #5477a9cc;
    color: #fff;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 99;
    display: none;
    width: 550px;
    white-space: normal;
  }
}
</style>
